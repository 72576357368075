@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-size: 1rem;
}

th,
td {
  white-space: nowrap;
}

// Beginning Scroll Bar
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(94, 156, 250, 0.3);
  background-color: #a3a3a3;
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(80, 70, 228)),
    color-stop(0.72, rgb(62, 77, 218)),
    color-stop(0.86, rgb(107, 122, 254))
  );
}

.new {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.main_header {
  background: none !important;

  header {
    background: rgba(255, 255, 255, 0.7) !important;
    box-shadow: none;
  }
}

.dock_header {
  background: none !important;

  header {
    background: rgba(255, 255, 255, 0.7) !important;
    box-shadow: 1px;
  }
}

// End ScrollBar
.custom_style .custom_boxshadow {
  box-shadow: 0px 6px 16px #3469cb29;
  border: 1px solid rgb(240, 240, 240);
}

.custom_style .custom_boxshadow2 {
  box-shadow: 0 30px 30px -25px rgb(65 51 183 / 25%);
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.p_tb {
  padding: 70px 0 50px 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  // background: url('https://images.unsplash.com/photo-1513925031621-b7ebee758f90?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80') no-repeat center center / cover;
  // background: url('../../../images/bg.jpg') no-repeat center center / cover;
  // background: url("../public/images/bg.jpg") no-repeat center center / cover;
  // background-image: linear-gradient(rgb(131 173 249),rgb(255, 255, 241), rgb(255, 255, 241), rgb(139 212 79));
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.background {
  background: url(../public/images/bg.jpg) no-repeat center center/cover !important;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

.text-center {
  text-align: center;
}

.primary-btn {
  background: #1682c5 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  margin-top: 10px !important;
  color: #fff !important;

  @media (max-width: 767px) {
    width: 100%;
    min-height: 52px !important;
  }
}

.login-wrapper {
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
  width: 600px !important;
  max-width: 90% !important;
  margin: 0 auto;
  text-shadow: none !important;

  @media (max-width: 767px) {
    margin: 0 auto;
    padding: 40px 30px !important;
    width: 340px !important;
    max-width: 100% !important;
  }

  label {
    // color: #fff !important;
    color: #000 !important;
    font-size: 16px !important;
  }

  h6 {
    // color: #fff;
    color: #000;
    font-size: 22px;
    margin: 0 0 30px 0;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  .form-input input::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
    transition: 0.3s ease-in-out;
  }

  .form-input input:focus::placeholder {
    opacity: 0;
  }

  .form-input {
    max-width: 100%;
    font-size: 16px;

    input {
      backdrop-filter: blur(0px) !important;
      -webkit-backdrop-filter: blur(0px) !important;
      box-shadow: none !important;
      padding: 10px 14px 10px 14px;
      // color: #fff;
      color: #000;
      text-shadow: none;
      height: 55px;
      font-size: 16px;
    }

    fieldset {
      // top: -4px !important;
      height: 60px !important;
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  .box2 {
    @media (max-width: 767px) {
      right: -10px !important;
      bottom: -56px !important;
    }
  }

  .StripeElement {
    padding: 17px 14px;
    // background: none;
  }
}

.login {
  position: relative;
  padding: 40px;
  font-family: "Roboto", sans-serif;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.login::before,
.login .box1,
.login .box2 {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  background-clip: padding-box;
}

.login::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
}

.login .box1 {
  position: absolute;
  z-index: 1;
  left: -50px;
  top: -64px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.login .box2 {
  position: absolute;
  z-index: 1;
  right: -30px;
  bottom: -30px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.login h1 {
  margin: 0 0 20px;
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.form-input {
  display: block;
  width: 300px;
  height: 50px;
  margin-bottom: 20px;
}

.form-input input {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
}

.form-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}

.form-input input:focus::placeholder {
  opacity: 0;
}

.form-button {
  display: block;
  margin: 0 auto;
  padding: 15px 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: rgba(44, 58, 17, 0.8);
  border: 0;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.form-button:hover {
  background-color: rgba(44, 58, 17, 1);
}

// Stripe
.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(94, 156, 250, 0.3);
  background-color: #a3a3a3;
  border-radius: 8px;
}

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(80, 70, 228)),
    color-stop(0.72, rgb(62, 77, 218)),
    color-stop(0.86, rgb(107, 122, 254))
  );
}

// End ScrollBar
.custom_style .custom_boxshadow {
  box-shadow: 0px 6px 16px #3469cb29;
  border: 1px solid rgb(240, 240, 240);
}

.custom_style .custom_boxshadow2 {
  box-shadow: 0 30px 30px -25px rgb(65 51 183 / 25%);
}

.img_fluid {
  max-width: 100%;
  height: auto;
}

.p_tb {
  padding: 70px 0 50px 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  // background: url('https://images.unsplash.com/photo-1513925031621-b7ebee758f90?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2134&q=80') no-repeat center center / cover;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.login {
  position: relative;
  padding: 40px;
  font-family: "Roboto", sans-serif;
}

.login::before,
.login .box1,
.login .box2 {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  background-clip: padding-box;
}

.login::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
}

.login .box1 {
  position: absolute;
  z-index: 1;
  left: -50px;
  top: -64px;
  width: 100px;
  height: 100px;
  border-radius: 10px;
}

.login .box2 {
  position: absolute;
  z-index: 1;
  right: -30px;
  bottom: -30px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.login h1 {
  margin: 0 0 20px;
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.form-input {
  display: block;
  width: 300px;
  height: 50px;
  margin-bottom: 20px;
}

.form-input input {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  outline: none;
}

.form-input input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.3s ease-in-out;
}

.form-input input:focus::placeholder {
  opacity: 0;
}

.form-button {
  display: block;
  margin: 0 auto;
  padding: 15px 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: rgba(44, 58, 17, 0.8);
  border: 0;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.form-button:hover {
  background-color: rgba(44, 58, 17, 1);
}

.youtube-link {
  position: fixed;
  left: 20px;
  bottom: 20px;
  color: #fff;
  text-decoration: none;
  font-size: 12px;
}

//

* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  margin: 0;
}

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.main_footer {
  margin-top: 30px !important;
  padding: 20px 0px 10px 0 !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: 767px) {
    margin-top: 30px !important;
  }

  h6 {
    color: #fff;
    text-shadow: 2px 4px 2px #000;
  }
}

.brandlogo {
  min-height: 65px;
  width: 270px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: 767px) {
    width: 190px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.dockbrandlogo {
  // min-height: 65px;
  width: 120px;
  margin: 0 auto;
  // padding-top: 10px;
  // padding-bottom: 10px;
  // @media (max-width: 767px) {
  //   width: 190px;
  //   padding-top: 7px;
  //   padding-bottom: 7px;
  // }
}

//

.choosebtn {
  background: none;
  color: #000;
  box-shadow: none;
  border: 0;
  text-transform: capitalize;
}

.choosebtn:hover,
.choosebtn:focus {
  background: none;
  box-shadow: none;
  border: 0;
}

.cards-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
}

.cards-list li h4,
.cards-list li h5,
.cards-list li h6 {
  color: #fff;
  margin: 0;
  padding: 2px 0;
  font-size: 12px;
  line-height: normal;
  font-weight: normal;
  text-align: left;
}

.cards-list li img {
  vertical-align: middle;
  margin-left: 5px;
  width: 25px;
}

.cards-list li h4 {
  font-weight: bold;
}

.cards-list li h6 {
  margin-bottom: 14px;
}

.cards-list li:first-child {
  border-top: 1px dashed #bdbdbd;
}

.cards-list li {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0;
  padding: 10px 0 18px 0;
  border-bottom: 1px dashed #bdbdbd;
}

.cards-list [type="radio"]:checked,
.cards-list [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.cards-list [type="radio"]:checked + label,
.cards-list [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 60px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: normal;
  line-height: 25px;
  color: #fff;
  margin: 0;
}

.cards-list [type="radio"]:checked + label:before,
.cards-list [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border: 1px solid #1682c5;
  border-radius: 100%;
  background: #fff;
}

.cards-list [type="radio"]:checked + label:after,
.cards-list [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  background: #1682c5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.cards-list [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cards-list [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

// Google Pay and Apple Pay buttons
.StripeElement {
  display: block;
  // margin: 10px 0 20px 0;
  max-width: 500px;
  // padding: 10px 14px;
  font-size: 1em;
  font-family: Nunito, sans-serif;
  /* box-shadow: rgb(50 50 93 / 15%) 0px 1px 3px, rgb(0 0 0 / 2%) 0px 1px 0px; */
  /* border: 0; */
  outline: 0;
  border-radius: 4px;
  background: none !important;
}

/* .............................payment........................ */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

#payment-wrapper .payment-heading {
  // font-family: "Neutrif Pro";
  font-family: Nunito, sans-serif;
  font-weight: 600 !important;
  font-size: 28.2793px;
  color: #000;
  margin: 0 0 60px 0;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 14px;
    margin: 0 0 30px 0;
  }
}

#payment-wrapper .payment-type {
  // border: 1px solid #fff;
  // border-radius: 5px;
}

#payment-wrapper .payment-type-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #fff;
  padding: 6px 13px;
  background: rgba(237, 237, 237, 0.69);
  box-shadow: 1.75426px 1.75426px 5.26279px rgba(0, 0, 0, 0.25);
  border-radius: 14.0341px;
  margin-bottom: 28px;
}

#payment-wrapper .payment-type-list:last-child {
  border-bottom: none;
}

#payment-wrapper .payment-img {
  width: 40px;
  height: 40px;
}

#payment-wrapper .payment-img img {
  width: 100%;
  height: 100%;
}

#payment-wrapper .payment-type h4 {
  font-family: Nunito, sans-serif;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 400;
}

#payment-wrapper .by-tapping-pay {
  font-family: "Neutrif Pro";
  font-size: 15px;
  font-weight: 400;
  color: #575757;
}

#payment-wrapper .by-tapping-pay a {
  text-decoration: none;
  color: #1a9ce3;
}

#payment-wrapper {
  padding: 15px;
}

/* ........................google-pay.......................... */
#google-pay .google-pay-wrapper {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
}

#google-pay .google_pay {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #c1c1c1;
  padding: 13px;
  font-family: Nunito, sans-serif;
}

#google-pay .google-pay-img {
  width: 40px;
  height: 40px;
}

#google-pay .google-pay-img img {
  width: 100%;
  height: 100%;
}

#google-pay .google_pay_btn {
  text-align: center;
}

#google-pay .google_pay-btn {
  background-color: #000;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  border-radius: 5px;
  margin: 0 auto;
}

#google-pay .google_pay h4 {
  font-family: Nunito, sans-serif;
  margin: 0 0 0 20px;
  font-size: 16px;
  font-weight: 400;
}

#google-pay .pay-btn-box {
  padding: 20px 13px;
}

#google-pay .google_pay-btn span {
  font-size: 17px;
  font-weight: 500;
  font-family: "Neutrif Pro";
  color: #fff;
  margin: 0 0 0 6px;
}

#google-pay .google_pay-btn img {
  width: 30px;
  height: 30px;
}

#google-pay .choose_another_pay {
  // font-family: "Neutrif Pro";
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

#google-pay .by_tapping {
  font-family: "Neutrif Pro";
  color: #575757;
  font-size: 16px;
  font-weight: 400;
}

#google-pay .by_tapping a {
  text-decoration: none;
  color: #1a9ce3;
}

#google-pay {
  padding: 15px;
}

//
/////////////////////
.removemethod {
  display: flex;
}

.buttonDelete {
  padding: 9px;
  margin: 0 auto;
  box-shadow: none;
}

.buttonDelete:hover {
  box-shadow: none;
}

/////////////////////react-confirm-alert///////////////@at-root

.react-confirm-alert {
  width: 388px;
  padding: 20px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #666;
}

.react-confirm-alert-overlay {
  background: none !important;
}

// .react-confirm-alert {
//   width: 304px;
//   padding: 30px;
//   text-align: left;
//   background: #fff;
//   border-radius: 10px;
//   box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
//   color: #666;
//   height: 165px;
// }

h1.alert__title {
  font-size: 20px;
}

p.alert__body {
  font-size: 16px;
}

button.alert__btn.alert__btn--yes {
  background: #1682c5;
  margin-left: 10px;
  text-transform: capitalize;
  height: auto;
  line-height: normal;
  padding: 7px 15px;
}

button.alert__btn.alert__btn--no {
  background: #605b5b;
  text-transform: capitalize;
  height: auto;
  line-height: normal;
  padding: 7px 15px;
}

.payment-wrapper-outer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  width: 100%;
  @media (max-width: 320px) {
    position: static;
    transform: none;
  }
}

.loader-img {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 45%;
  top: 45%;
  transform: translate(-50%, -50%);
  color: #666 !important;
}

.error-alert {
  font-family: "Neutrif Pro";
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #ed2626;
}

.footer--wrapper {
  /* background-color: #fff; */
  color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
}

// file upload////
.ewqTBN {
  height: 194px !important;
}

.page-bg {
  position: fixed;
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.form-content {
  max-width: 600px;
  margin: 0 auto !important;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 5px;
}

.header-wrapper button {
  width: 95% !important;
  margin: 0 !important;
}

.loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

// .header-wrapper .bzrgMi {
//   width: 15%;
// }
// .header-wrapper .user--icon span {
//   justify-content: start;
// }
// .header-wrapper .user--icon {
//   float: right;
// }

.header-wrapper .user--icon span {
  justify-content: end;
}

// .header-wrapper .user--icon {
//   float: right;
// }
.profileWrapper {
  margin: 0 15px;
}

.profilePage {
  background-color: rgb(245, 245, 245);
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  padding: 30px;

  @media screen and (max-width: 767px) {
    padding: 15px;
  }

  .ProfileHeader {
    .subHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
      }

      button {
        background: black;
        color: white;
        padding: 15px;

        @media screen and (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 7px;
    }
  }
}

.table-responsive {
  overflow-x: auto;

  thead {
    background: none;
  }
}

.shortly__loading {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  font-size: x-large;
}

.loginMainWrappeer {
  .headerLogin {
    background: linear-gradient(
      270deg,
      #b006f7 50%,
      #2669f9 107.89%,
      rgba(176, 6, 247, 0) 108.36%
    );
    height: 35px;
  }
  .loginwrapperheight {
    height: 100vh !important;
    .centerContent {
    max-width: 500px;
    margin: 0 auto;
    }
  }
  .loginwrapper {
    height: calc(100vh - 150.14px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    padding: 20px;
  }
  @media (max-width: 767px) {
    .loginwrapper {
      height: auto;
      min-height: calc(100vh - 257px);
    }
  }
  @media (max-width: 320px) {
    .loginwrapper {
      height: auto !important;
      min-height: calc(100vh - 200px);
    }
  }

  .loginwrapperACH {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ededed;
    padding: 20px;
  }

  h6 {
    font-family: Nunito, sans-serif;
    font-weight: 600 !important;
    font-size: 28.2793px;
    color: #000;
    margin: 0 0 60px 0;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  .cardBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(237, 237, 237, 0.69);
    box-shadow: 1.76623px 1.76623px 5.29869px rgba(0, 0, 0, 0.25);
    border-radius: 14.1298px;
    margin: 50px 0;
    padding: 0 20px;

    @media screen and (max-width: 767px) {
      margin: 25px 0;
    }

    .left {
      width: 65%;

      @media screen and (max-width: 767px) {
        width: 70%;
      }
    }

    .right {
      width: 25%;

      @media screen and (max-width: 767px) {
        width: 30%;
      }
    }

    input {
      width: 100%;
      background: none;
      box-shadow: none;
      margin: 0;
      font-family: sans-serif;
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  .gradientBtn {
    background: linear-gradient(
      270deg,
      #b006f7 50%,
      #2669f9 107.89%,
      rgba(176, 6, 247, 0) 108.36%
    );
    border-radius: 12.0147px;
    max-width: 205px;
    width: 100%;
    color: #fff;
    margin: 0 auto;
    display: flex;
    text-align: center;
    height: 47px;
    justify-content: center;
    align-items: center;
  }
}

.cardDetailsFooter {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 23px 30px;
  background: #ededed;

  @media screen and (max-width: 767px) {
    padding: 23px 20px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;

    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: 11px;
      width: 100%;
    }

    a {
      color: #b006f7;
      text-decoration: none;
    }
  }

  .logoBlock {
    @media screen and (max-width: 767px) {
      margin: 0 auto 90px;
    }
    @media screen and (max-width: 320px) {
      margin: 0 auto 30px;
    }
    img {
      max-width: 130px;
      width: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: 767px) {
  .mobile-wrapper-block {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
  }
}

////// MONTLHY SUBCRIPTION DASHBOARD/////
.card-header-user {
  background: #f1f1f1;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
}
.reservation-list {
  height: calc(100vh - 370px);
  overflow-y: auto;
  padding: 10px 0 !important;
}
.main-subscription .css-e1mnrr,
.main-subscription .css-zbwuqm {
  height: auto !important;
  bottom: 8px !important;
}
.main-subscription .css-e1mnrr button {
  background: #000 !important;
  color: #fff !important;
  border-radius: 10px !important;
  margin-right: 10px !important;
}
.main-subscription .css-zbwuqm button {
  background: #000 !important;
  color: #fff !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
}
.subscription-loader span {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.buttom-sub {
  // position: absolute;
  // bottom: 10px;
  // left: 20px;
  // right: 20px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.savebtn {
  width: 48%;
  background: #000 !important;
  color: #fff !important;
  margin: 14px 0 !important;
  padding: 20px 0 !important;
  border: 1px solid #000 !important;
  border-radius: 7px !important;
}
.cancelbtn {
  width: 48%;
  background: #fff;
  color: #000;
  margin: 14px 0;
  padding: 20px 0;
  border: 1px solid #000 !important;
  border-radius: 7px !important;
  margin-left: 10px !important;
}
.css-1abc02a,
.css-hn784z {
  height: auto !important;
  display: inline-block;
  bottom: 0;
  top: auto !important;
}
.css-1f8sh1y {
  height: calc(100vh - 150px) !important;
}
.navButton-sub {
  height: 30px !important;
}
.navButton-sub button {
  height: 30px !important;
  background: #000;
  height: 30px;
  color: #fff;
  margin: 0 10px;
}
.css-1m9128y {
  width: 100%;
  margin-top: 10px;
  text-align: center;
  opacity: 0;
}
.edit-licenseplatez {
  min-width: 30px;
  height: 30px;
}
.edit-licenseplatez svg {
  font-size: 20px;
}
.rounded-input {
  border-radius: 8px;
  padding: 10px 16px;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  width: 100%;
  margin: 0 auto 31px;
}
.heading-otp {
  width: 600px;
  max-width: 100%;
   /* Mobile Devices */
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
}


.shadow-bottom {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
